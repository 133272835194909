import React from 'react';
import classNames from 'classnames';
import { v4 as guid } from 'uuid';
import Marquee3k from 'marquee3000';

export class Ticker extends React.Component {
  constructor () {
    super();
    this.state = { id: guid() };
  }

  get className () {
    return classNames({
      'lcm-ticker': true
    });
  }

  componentDidMount () {
    // Wait for the fonts to load
    if (document.fonts && document.fonts.ready) {
      document.fonts.ready.then(this.initMarquee);
    } else {
      setTimeout(this.initMarquee, 1000)
    }
  }

  initMarquee = () => {
    Marquee3k.init({ selector: 'lcm-ticker' });
  }

  render = () => (
    <div className={this.className} id={this.state.id} data-speed="2">
      <span>{this.props.children}&nbsp;</span>
    </div>
  )
}